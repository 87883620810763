import "./Technologies.css";
import React, { useEffect } from "react";

import john from "../../../assets/john-schnobrich.png";
import handsome from "../../../assets/handsome-businessman.png";
import young from "../../../assets/young-businessman.png";
import Artboard from "../../../assets/Artboard.png";
import Artboard2 from "../../../assets/Artboard2.png";
import Group from "../../../assets/Group.png";
import uiux from "../../../assets/uiux.png";
import product from "../../../assets/product.png";
import softwarequailty from "../../../assets/softwarequailty.png";
import image3 from "../../../assets/image3.png";
import linkdin from "../../../assets/linkdin.png";
import phone from "../../../assets/phone.png";
import { useNavigate } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import icon from "../../../assets/Icon.png";
import AI from "../../../assets/Ai.png";
import ContactUs from "../Home/ContactUs";
const Technologies = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [navigate]);

  return (
    <div
      style={{
        backgroundColor: "white",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      {/* P1 */}
      <div
        style={{
          width: "100%",
          height: 800,
          background:
            "linear-gradient(269.87deg, rgb(21 7 73) -2.21%, rgb(0 34 104) 103.58%)",
        }}
      >
        <div
          className="Technologies-P1"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            width: "100%",
            height: 800,
            backgroundSize: "cover",
            backgroundImage: `url(${require("../../../assets/technologiesBackgroundImg.png")})`,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              width: "100%",
              alignItems: "center",
            }}
          >
            <Navbar />
            <div style={{ marginTop: 130, width: "80%" }}>
              <p
                className="main-Heading-technologies"
                style={{
                  color: "#01EFF7",
                  fontSize: "55px",
                  fontWeight: "600",
                }}
              >
                AI-based intelligent
                <br />
                Solutions
              </p>
              <p
                className="Heading-Details-Details"
                style={{
                  fontSize: "25px",
                  width: "60%",
                  fontWeight: "600",
                  color: "white",
                  lineHeight: "30px",
                }}
              >
                Unlock your organisation's potential with our AI-based
                intelligent
                {/* <br /> */}
                solutions. We optimise processes, enhance decision making,
                {/* <br /> */}
                and prioritise data security to drive efficiency and
                {/* <br /> */}
                success in the fast paced digital landscape.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* P2 */}
      <div
        className="P2-Div"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "80%",
        }}
      >
        <div
          className="Tech-Solutions"
          style={{
            background: "#F6F6F6",
            width: "20%",
            display: "flex",
            borderRadius: 15,
            flexDirection: "column",
            alignItems: "center",
            height: "30%",
            marginTop: "-58px",
            WebkitBoxShadow: " 0px -15px 4px 13px rgba(1,239,247,0.47)",
          }}
        >
          <img
            className="Tech-Image"
            style={{ borderRadius: 200, marginTop: "-35%" }}
            src={john}
            alt="tech"
          />
          <p
            style={{
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: 18,
              lineHeight: "150%",
              textAlign: "center",
              color: "#2D3748",
            }}
          >
            Perfect solution that <br /> business demands
          </p>
        </div>
        <div
          className="Tech-Solutions"
          style={{
            background: "#F6F6F6",
            width: "20%",
            display: "flex",
            borderRadius: 5,
            flexDirection: "column",
            alignItems: "center",
            height: "30%",
            marginTop: "-58px",
            WebkitBoxShadow: " 0px -15px 4px 13px rgba(1,239,247,0.47)",
          }}
        >
          {" "}
          <img
            className="Tech-Image"
            style={{ borderRadius: 200, marginTop: "-35%" }}
            src={handsome}
            alt="tech"
          />
          <p
            style={{
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: 18,
              lineHeight: "150%",
              textAlign: "center",
              color: "#2D3748",
            }}
          >
            Providing excellent <br />
            support for solution
          </p>
        </div>
        <div
          className="Tech-Solutions"
          style={{
            background: "#F6F6F6",
            width: "20%",
            display: "flex",
            borderRadius: 5,
            flexDirection: "column",
            alignItems: "center",
            height: "30%",
            marginTop: "-58px",
            WebkitBoxShadow: " 0px -15px 4px 13px rgba(1,239,247,0.47)",
          }}
        >
          {" "}
          <img
            className="Tech-Image"
            style={{ borderRadius: 200, marginTop: "-35%" }}
            src={young}
          />
          <p
            style={{
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: 18,
              lineHeight: "150%",
              textAlign: "center",
              color: "#2D3748",
            }}
          >
            We are new age <br />
            technology IT agency
          </p>
        </div>
      </div>
      {/* P3 */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          width: "100%",
          height: 870,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "80%",
          }}
        >
          <div
            className="Imag-Div"
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img width={"80%"} className="image" alt="Image" src={Artboard} />
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <p
              className="p3-headig"
              style={{
                color: "#01EFF7",
                fontSize: "55px",
                fontWeight: "700",
                margin: 0,
                marginBottom: 5,
              }}
            >
              Web App
            </p>
            <p
              className="p3-headig"
              style={{
                color: "#2D3748",
                fontSize: "55px",
                fontWeight: "700",
                margin: 0,
                marginBottom: 10,
              }}
            >
              Development
            </p>
            <p
              style={{ color: "#12062E", fontSize: "25px", fontWeight: "400" }}
              className="p3-Details"
            >
              With a focus on your business needs, Eastman creates exceptional{" "}
              web apps that drive engagement and boost productivity through the
              integration of cutting-edge technologies and innovative design.
            </p>
            <button
              onClick={() => navigate("/lets-talk")}
              style={{
                width: 190,
                background:
                  "linear-gradient(264.78deg, #1E43DC -11.3%, #0BA2EA 129.77%)",
                boxShadow: "0px 22px 11px -20px rgba(0, 0, 0, 0.15)",
                borderRadius: 5,
                color: "#ffffff",
                textAlign: "center",
                padding: "15px 26px",
                fontSize: 18,
                border: "none",
                fontWeight: 700,
              }}
            >
              Let's Talk Now
            </button>
          </div>
        </div>
      </div>
      {/* P4 */}
      <div
        style={{
          display: "flex",
          background:
            "linear-gradient(269.87deg, #042573 -2.21%, #32175E 103.58%)",
          flexDirection: "row",
          justifyContent: "center",
          width: "100%",
          height: 980,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "80%",
            flexDirection: "row-reverse",
          }}
        >
          <div
            className="Imag-Div"
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img className="image" alt="Image" src={Group} />
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <p
              className="p3-headig"
              style={{
                color: "#01EFF7",
                fontSize: "55px",
                fontWeight: "700",
                margin: 0,
                marginBottom: 5,
              }}
            >
              Mobile App
            </p>
            <p
              className="p3-headig"
              style={{
                color: "#fff",
                fontSize: "55px",
                fontWeight: "700",
                margin: 0,
                marginBottom: 10,
              }}
            >
              Development
            </p>
            <p
              className="p3-Details"
              style={{ color: "#fff", fontSize: "25px", fontWeight: "400" }}
            >
              Elevate your brand and engage your target audience with our{" "}
              innovative and user-centric mobile apps. Our experienced{" "}
              developers leverage the latest technologies to create exceptional
              mobile experiences that fuel business growth and make a lasting
              impression.
            </p>
            <button
              onClick={() => navigate("/lets-talk")}
              style={{
                width: 190,
                background:
                  "linear-gradient(264.78deg, #1E43DC -11.3%, #0BA2EA 129.77%)",
                boxShadow: "0px 22px 11px -20px rgba(0, 0, 0, 0.15)",
                borderRadius: 5,
                color: "#ffffff",
                textAlign: "center",
                padding: "15px 26px",
                fontSize: 18,
                border: "none",
                fontWeight: 700,
              }}
            >
              Let's Talk Now
            </button>
          </div>
        </div>
      </div>
      {/* P5 */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          width: "100%",
          height: 980,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "80%",
          }}
        >
          <div
            className="Imag-Div"
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img className="image" alt="Image" src={Artboard2} />
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <p
              className="p3-headig"
              style={{
                color: "#01EFF7",
                fontSize: "55px",
                fontWeight: "700",
                margin: 0,
                marginBottom: 5,
              }}
            >
              Cloud-based
            </p>
            <p
              className="p3-headig"
              style={{
                color: "#2D3748",
                fontSize: "55px",
                fontWeight: "700",
                margin: 0,
                marginBottom: 10,
              }}
            >
              Solutions
            </p>
            <p
              className="p3-Details"
              style={{ color: "#12062E", fontSize: "25px", fontWeight: "400" }}
            >
              Explore our innovative cloud-based business solutions. Our{" "}
              customcloud-based solutions improve data management,{" "}
              collaboration, and operations for a digital edge. Authoritatively
              underwhelm excellent
            </p>
            <button
              onClick={() => navigate("/lets-talk")}
              style={{
                width: 190,
                background:
                  "linear-gradient(264.78deg, #1E43DC -11.3%, #0BA2EA 129.77%)",
                boxShadow: "0px 22px 11px -20px rgba(0, 0, 0, 0.15)",
                borderRadius: 5,
                color: "#ffffff",
                textAlign: "center",
                padding: "15px 26px",
                fontSize: 18,
                border: "none",
                fontWeight: 700,
              }}
            >
              Let's Talk Now
            </button>
          </div>
        </div>
      </div>
      {/* P6 */}
      <div
        style={{
          display: "flex",
          background:
            "linear-gradient(269.87deg, #042573 -2.21%, #32175E 103.58%)",
          flexDirection: "row",
          justifyContent: "center",
          width: "100%",
          height: 980,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "80%",
            flexDirection: "row-reverse",
          }}
        >
          <div
            className="Imag-Div"
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img className="image" alt="Image" src={uiux} />
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <p
              className="p3-headig"
              style={{
                color: "#01EFF7",
                fontSize: "55px",
                fontWeight: "700",
                margin: 0,
                marginBottom: 5,
              }}
            >
              UI/UX
            </p>
            <p
              className="p3-headig"
              style={{
                color: "#fff",
                fontSize: "55px",
                fontWeight: "700",
                margin: 0,
                marginBottom: 10,
              }}
            >
              Design
            </p>
            <p
              className="p3-Details"
              style={{ color: "#fff", fontSize: "25px", fontWeight: "400" }}
            >
              Our talented designers develop stunning, intuitive UI/UX designs.
              With a user-centric approach, we improve user experiences and
              promote engagement, turning your ideas into appealing designs that
              endure.
            </p>
            <button
              style={{
                width: 190,
                background:
                  "linear-gradient(264.78deg, #1E43DC -11.3%, #0BA2EA 129.77%)",
                boxShadow: "0px 22px 11px -20px rgba(0, 0, 0, 0.15)",
                borderRadius: 5,
                color: "#ffffff",
                textAlign: "center",
                padding: "15px 26px",
                fontSize: 18,
                border: "none",
                fontWeight: 700,
              }}
              onClick={() => navigate("/lets-talk")}
            >
              Let's Talk Now
            </button>
          </div>
        </div>
      </div>
      {/* P7 */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          width: "100%",
          height: 980,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "80%",
          }}
        >
          <div
            className="Imag-Div"
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img className="image" alt="Image" src={product} />
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <p
              className="p3-headig"
              style={{
                color: "#01EFF7",
                fontSize: "55px",
                fontWeight: "700",
                margin: 0,
                marginBottom: 5,
              }}
            >
              Product
            </p>
            <p
              className="p3-headig"
              style={{
                color: "#2D3748",
                fontSize: "55px",
                fontWeight: "700",
                margin: 0,
                marginBottom: 10,
              }}
            >
              Strategy and Consulting
            </p>
            <p
              className="p3-Details"
              style={{ color: "#12062E", fontSize: "25px", fontWeight: "400" }}
            >
              Our skilled professionals provide comprehensive product strategy
              and consulting services, driving innovation and market growth
              through valuable insights and guidance throughout the product
              lifecycle.
            </p>
            <button
              style={{
                width: 190,
                background:
                  "linear-gradient(264.78deg, #1E43DC -11.3%, #0BA2EA 129.77%)",
                boxShadow: "0px 22px 11px -20px rgba(0, 0, 0, 0.15)",
                borderRadius: 5,
                color: "#ffffff",
                textAlign: "center",
                padding: "15px 26px",
                fontSize: 18,
                border: "none",
                fontWeight: 700,
              }}
              onClick={() => navigate("/lets-talk")}
            >
              Let's Talk Now
            </button>
          </div>
        </div>
      </div>
      {/* P8 */}
      <div
        style={{
          display: "flex",
          background: "#020727",
          flexDirection: "row",
          justifyContent: "center",
          width: "100%",
          height: 980,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "80%",
            flexDirection: "row-reverse",
          }}
        >
          <div
            className="Imag-Div"
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              style={{ width: "100%" }}
              className="image"
              alt="Image"
              src={image3}
            />
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <p
              className="p3-headig"
              style={{
                color: "#01EFF7",
                fontSize: "55px",
                fontWeight: "700",
                margin: 0,
                marginBottom: 5,
              }}
            >
              Technology
            </p>
            <p
              className="p3-headig"
              style={{
                color: "#fff",
                fontSize: "55px",
                fontWeight: "700",
                margin: 0,
                marginBottom: 10,
              }}
            >
              Consulting
            </p>
            <p
              className="p3-Details"
              style={{ color: "#fff", fontSize: "25px", fontWeight: "400" }}
            >
              Unlock your business's full potential with our top-notch
              technology consulting services, offering strategic guidance and
              innovative solutions to navigate the complex world of technology.
            </p>
            <button
              style={{
                width: 190,
                background:
                  "linear-gradient(264.78deg, #1E43DC -11.3%, #0BA2EA 129.77%)",
                boxShadow: "0px 22px 11px -20px rgba(0, 0, 0, 0.15)",
                borderRadius: 5,
                color: "#ffffff",
                textAlign: "center",
                padding: "15px 26px",
                fontSize: 18,
                border: "none",
                fontWeight: 700,
              }}
              onClick={() => navigate("/lets-talk")}
            >
              Let's Talk Now
            </button>
          </div>
        </div>
      </div>
      {/* P9 */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          width: "100%",
          height: 980,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "80%",
          }}
        >
          <div
            className="Imag-Div"
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img className="image" alt="Image" src={softwarequailty} />
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <p
              className="p3-headig"
              style={{
                color: "#01EFF7",
                fontSize: "55px",
                fontWeight: "700",
                margin: 0,
                marginBottom: 5,
              }}
            >
              Software Testing
            </p>
            <p
              className="p3-headig"
              style={{
                color: "#2D3748",
                fontSize: "55px",
                fontWeight: "700",
                margin: 0,
                marginBottom: 10,
              }}
            >
              and Quality Assurance
            </p>
            <p
              className="p3-Details"
              style={{ color: "#12062E", fontSize: "25px", fontWeight: "400" }}
            >
              Our skilled professionals deliver exceptional software testing and
              quality assurance services, guaranteeing flawless performance and
              a seamless user experience.
            </p>
            <button
              onClick={() => navigate("/lets-talk")}
              style={{
                width: 190,
                background:
                  "linear-gradient(264.78deg, #1E43DC -11.3%, #0BA2EA 129.77%)",
                boxShadow: "0px 22px 11px -20px rgba(0, 0, 0, 0.15)",
                borderRadius: 5,
                color: "#ffffff",
                textAlign: "center",
                padding: "15px 26px",
                fontSize: 18,
                border: "none",
                fontWeight: 700,
              }}
            >
              Let's Talk Now
            </button>
          </div>
        </div>
      </div>
      {/* P8 */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          background: "#020727",
          justifyContent: "center",
          width: "100%",
          height: 980,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "80%",
            flexDirection: "row-reverse",
          }}
        >
          <div
            className="Imag-Div"
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              style={{ width: "80%" }}
              className="image"
              alt="Image"
              src={AI}
            />
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <p
              className="p3-headig"
              style={{
                color: "#01EFF7",
                fontSize: "55px",
                fontWeight: "700",
                margin: 0,
                marginBottom: 5,
              }}
            >
              AI Based Solutions
            </p>
            <p
              className="p3-headig"
              style={{
                color: "#fff",
                fontSize: "55px",
                fontWeight: "700",
                margin: 0,
                marginBottom: 10,
              }}
            >
              & Data Engineering
            </p>
            <p
              className="p3-Details"
              style={{ color: "#fff", fontSize: "25px", fontWeight: "400" }}
            >
              Eastman Innovations specializes in offering cutting-edge AI and
              Data Engineering services, helping businesses harness the power of
              data-driven solutions. From advanced machine learning algorithms
              to efficient data pipelines, we pave the way for organizations to
              thrive in the digital age.
            </p>
            <button
              style={{
                width: 190,
                background:
                  "linear-gradient(264.78deg, #1E43DC -11.3%, #0BA2EA 129.77%)",
                boxShadow: "0px 22px 11px -20px rgba(0, 0, 0, 0.15)",
                borderRadius: 5,
                color: "#ffffff",
                textAlign: "center",
                padding: "15px 26px",
                fontSize: 18,
                border: "none",
                fontWeight: 700,
              }}
              onClick={() => navigate("/lets-talk")}
            >
              Let's Talk Now
            </button>
          </div>
        </div>
      </div>
      {/* P10 */}
      <div
        className="footer"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          width: "100%",
          background:
            "linear-gradient(269.87deg, #042573 -2.21%, #32175E 103.58%)",
          paddingTop: 60,
        }}
      >
        <div
          className="Footer-components"
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "80%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div>
              <img
                onClick={() => navigate("/")}
                src={icon}
                style={{ cursor: "pointer", marginLeft: -10, marginBottom: 10 }}
              />
              <p
                style={{
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: 14,
                  color: "#FFFFFF",
                  cursor: "default",
                }}
              >
                Get Free Estimate
              </p>
              <a
                className="Email-font"
                style={{
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: 16,
                  color: "#FFFFFF",
                  textDecoration: "none",
                }}
                href="https://mail.google.com/mail/?view=cm&fs=1&to=ehsan@eastmanenterprises.com"
                target="_blank"
              >
                ehsan@eastmanenterprises.com
              </a>
            </div>

            <div
              className="Contect-Number"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <img width="20px" height="20px" src={phone} />
              <a
                href="tel:+923044243496"
                style={{
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: 16,
                  color: "#FFFFFF",
                  margin: 0,
                  textDecoration: "none",
                  marginLeft: 20,
                }}
              >
                +92 304 4243496
              </a>
            </div>
            <a
              style={{ marginBottom: 50 }}
              href="https://pk.linkedin.com/company/eastman-enterprises-pk"
              target="_blank"
            >
              <img
                className="linked"
                height="30px"
                width="30px"
                src={linkdin}
              />
            </a>
          </div>

          <div>
            <p
              style={{
                fontStyle: "normal",
                fontWeight: 700,
                fontSize: 18,
                marginBottom: 30,
                color: "#FFFFFF",
                cursor: "default",
              }}
            >
              Company
            </p>
            <p
              style={{
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: 14,

                color: "#FFFFFF",
                marginBottom: 22,
                opacity: 0.78,
                cursor: "pointer",
              }}
              onClick={() => navigate("/")}
            >
              Home
            </p>
            <p
              style={{
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: 14,
                cursor: "pointer",
                color: "#FFFFFF",
                marginBottom: 22,
                opacity: 0.78,
              }}
              onClick={() => navigate("/technologies")}
            >
              Technologies
            </p>
            <p
              style={{
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: 14,
                cursor: "pointer",
                color: "#FFFFFF",
                marginBottom: 22,
                opacity: 0.78,
              }}
              onClick={() => navigate("/portfolio")}
            >
              Portfolio
            </p>
            <p
              style={{
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: 14,
                cursor: "pointer",
                color: "#FFFFFF",
                marginBottom: 22,
                opacity: 0.78,
              }}
              onClick={() => navigate("/career")}
            >
              Career
            </p>
          </div>

          <div>
            <p
              style={{
                fontStyle: "normal",
                fontWeight: 700,
                fontSize: 18,
                marginBottom: 30,
                color: "#FFFFFF",
                cursor: "default",
              }}
            >
              Services
            </p>
            <p
              style={{
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: 14,

                color: "#FFFFFF",
                marginBottom: 22,
                opacity: 0.78,
                cursor: "default",
              }}
            >
              Android App Development
            </p>
            <p
              style={{
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: 14,

                color: "#FFFFFF",
                marginBottom: 22,
                opacity: 0.78,
                cursor: "default",
              }}
            >
              AngularJs Development
            </p>
            <p
              style={{
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: 14,
                cursor: "default",
                color: "#FFFFFF",
                marginBottom: 22,
                opacity: 0.78,
              }}
            >
              PHP Development
            </p>
            <p
              style={{
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: 14,
                cursor: "default",
                color: "#FFFFFF",
                marginBottom: 22,
                opacity: 0.78,
              }}
            >
              CodeIgniter Development
            </p>
            <p
              style={{
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: 14,
                cursor: "default",
                color: "#FFFFFF",
                marginBottom: 22,
                opacity: 0.78,
              }}
            >
              Net Development
            </p>
            <p
              style={{
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: 14,
                cursor: "default",
                color: "#FFFFFF",
                marginBottom: 22,
                opacity: 0.78,
              }}
            >
              Augmented Reality
            </p>
            <p
              style={{
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: 14,
                cursor: "default",
                color: "#FFFFFF",
                marginBottom: 22,
                opacity: 0.78,
              }}
            >
              AWS Development
            </p>
          </div>
          <ContactUs />
        </div>
      </div>
    </div>
  );
};

export default Technologies;
