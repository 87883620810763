import React, { useState } from "react";
import { NavLink, useNavigate, Link } from "react-router-dom";
import { Twirl as Hamburger } from "hamburger-react";
import "./Navbar.css";
import icon from "../../../assets/Icon.png";

const Navbar = ({ color }) => {
  const [showNavbar, setShowNavbar] = useState(false);
  const navigate = useNavigate();

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };

  return (
    <nav
      className="navbar"
      style={{
        backgroundColor: color ? color : "transparent",
      }}
    >
      <div style={{ width: "80%" }}>
        <div className="Navcontainer">
          <div className="logo">
            <img
              onClick={() => navigate("/")}
              className="logo"
              alt="Logo"
              src={icon}
              style={{ cursor: "pointer" }}
            />{" "}
          </div>
          <div className="menu-icon" onClick={handleShowNavbar}>
            <Hamburger
              color="#fff"
              toggled={showNavbar}
              toggle={setShowNavbar}
            />
          </div>
          <div
            style={{ marginTop: 20 }}
            className={`nav-elements  ${showNavbar && "active"}`}
          >
            <div>
              <ul>
                <li>
                  <Link
                    style={{ color: "white", textDecoration: "none" }}
                    to={"/"}
                    onClick={() => setShowNavbar(false)}
                  >
                    Home
                  </Link>
                </li>
                <li>
                  <Link
                    style={{ color: "white", textDecoration: "none" }}
                    to={"/technologies"}
                    onClick={() => setShowNavbar(false)}
                  >
                    Technologies
                  </Link>
                </li>
                <li>
                  <Link
                    style={{ color: "white", textDecoration: "none" }}
                    to={"/portfolio"}
                    onClick={() => setShowNavbar(false)}
                  >
                    Portfolio
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/career"}
                    style={{ color: "white", textDecoration: "none" }}
                    onClick={() => setShowNavbar(false)}
                  >
                    Career
                  </Link>
                </li>
                <li>
                  <button
                    className="header-comp-mobile"
                    onClick={() => {
                      navigate("/lets-talk");
                      setShowNavbar(false);
                    }}
                    style={{
                      width: 150,
                      background:
                        "linear-gradient(264.78deg, #1E43DC -11.3%, #0BA2EA 129.77%)",
                      boxShadow: "0px 22px 11px -20px rgba(0, 0, 0, 0.15)",
                      borderRadius: 5,
                      color: "#ffffff",
                      textAlign: "center",
                      padding: "15px 26px",
                      fontSize: 18,
                      border: "none",
                      fontWeight: 700,
                    }}
                  >
                    Let's Talk
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <button
            className="header-comp-web"
            onClick={() => navigate("/lets-talk")}
            style={{
              width: 150,
              background:
                "linear-gradient(264.78deg, #1E43DC -11.3%, #0BA2EA 129.77%)",
              boxShadow: "0px 22px 11px -20px rgba(0, 0, 0, 0.15)",
              borderRadius: 5,
              color: "#ffffff",
              textAlign: "center",
              padding: "15px 26px",
              fontSize: 18,
              border: "none",
              fontWeight: 700,
            }}
          >
            Let's Talk
          </button>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
