import React, { useState, useEffect } from "react";
import "./Portfollio.css";
import WebDev from "./WebDev";
import AppDev from "./AppDev";
import { useNavigate } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import AllProjects from "./AllProjects";

const Portfolio = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [navigate]);
  const [first, setFirst] = useState(2);
  const [selectedOption, setSelectedOption] = useState("Web Development");
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = (option) => {
    setIsOpen(!isOpen);
    setSelectedOption(option);
  };
  console.log("first", first);

  return (
    <div
      style={{
        backgroundColor: "white",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      <div
        style={{
          width: "100%",
          height: 840,
          background:
            "linear-gradient(269.87deg, rgb(10 196 206 ) -2.21%,  rgb(36 8 91 ) 103.58%)",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            width: "100%",
            height: 840,
            backgroundSize: "cover",
            backgroundImage: `url(${require("../../../assets/portfolio1.png")})`,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              width: "100%",
              alignItems: "center",
            }}
          >
            <Navbar />

            <div style={{ marginTop: 130, width: "80%" }}>
              <p
                className="main-Heading"
                style={{
                  color: "#01EFF7",
                  fontSize: "55px",
                  fontWeight: "600",
                }}
              >
                Projects
                <br />
                Highlights
              </p>
              <p
                className="Mian-Detail "
                style={{
                  fontSize: "25px",
                  fontWeight: "600",
                  color: "white",
                  width: "50%",
                }}
              >
                Explore our diverse portfolio showcasing our exceptional work
                <br className="no-br" />
                and achievements. Witness our expertise, innovation, and
                <br className="no-br" />
                commitment to delivering outstanding results for our clients,
                <br className="no-br" />
                and envision the possibilities for your own success.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        className="portfolio-Type"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          width: "80%",
          background: "#01EFF7",
          borderRadius: 15,
          height: 120,
          alignItems: "center",
          marginTop: "-60px",
          zIndex: 1,
          flexWrap: "wrap",
        }}
      >
        <p
          className="projects"
          onClick={() => {
            setFirst(1);
          }}
          style={{
            fontStyle: "normal",
            fontWeight: "700",
            fontSize: 24,
            cursor: "pointer",
            color: first === 1 ? "#1669E1" : "#000",
          }}
        >
          Web Development
        </p>
        <p
          className="projects"
          style={{
            fontStyle: "normal",
            fontWeight: "700",
            fontSize: 24,
            cursor: "pointer",
            color: first === 2 ? "#1669E1" : "#000",
          }}
          onClick={() => {
            setFirst(2);
          }}
        >
          All
        </p>
        <p
          className="projects"
          onClick={() => {
            setFirst(3);
          }}
          style={{
            fontStyle: "normal",
            fontWeight: "700",
            fontSize: 24,
            cursor: "pointer",
            color: first === 3 ? "#1669E1" : "#000",
          }}
        >
          Apps Development
        </p>
      </div>
      <div
        style={{
          width: "100%",
          marginTop: "-60px",
          height: "300px",
          background: "#F3F3F3",
        }}
      >
        {first === 1 ? (
          <WebDev />
        ) : first === 2 ? (
          <AllProjects />
        ) : (
          first === 3 && <AppDev />
        )}
      </div>
    </div>
  );
};

export default Portfolio;
