import React, { useEffect } from "react";
import "./Career.css";
import { Link } from "react-router-dom";
import "./style.css";
import career from "../../../assets/career.png";

import avatar from "../../../assets/avatar.png";
import cap from "../../../assets/cap.png";
import pause from "../../../assets/pause.png";
import rank from "../../../assets/rank.png";
import hiring from "../../../assets/hiring.png";
import Form from "react-bootstrap/Form";
import Navbar from "../Navbar/Navbar";
import facebook from "../../../assets/facebook.png";
import instagram from "../../../assets/instagram.png";
import linkdin from "../../../assets/linkdin.png";
import phone from "../../../assets/phone.png";
import twitter from "../../../assets/twitter.png";
import youtube from "../../../assets/youtube.png";
import icon from "../../../assets/Icon.png";
import ContactUs from "../Home/ContactUs";

import { useNavigate } from "react-router-dom";
const CareerDesktop = () => {
  const navigate = useNavigate();
  const jobs = [
    {
      position: "Wordpress Developer",
      Experience: "Experience",
      ExperienceTime: "2 Years",
      Deadline: "Deadline",
      deadlineTime: "2021-05-08",
    },
    {
      position: "Wordpress Developer",
      Experience: "Experience",
      ExperienceTime: "2 Years",
      Deadline: "Deadline",
      deadlineTime: "2021-05-08",
    },
    {
      position: "Wordpress Developer",
      Experience: "Experience",
      ExperienceTime: "2 Years",
      Deadline: "Deadline",
      deadlineTime: "2021-05-08",
    },
    {
      position: "Wordpress Developer",
      Experience: "Experience",
      ExperienceTime: "2 Years",
      Deadline: "Deadline",
      deadlineTime: "2021-05-08",
    },
    {
      position: "Wordpress Developer",
      Experience: "Experience",
      ExperienceTime: "2 Years",
      Deadline: "Deadline",
      deadlineTime: "2021-05-08",
    },
  ];

  const handleClickScroll = () => {
    const element = document.getElementById("second");
    console.log("element====>", element);
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div
      style={{
        backgroundColor: "white",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          width: "100%",
          height: 865,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            width: "100%",
            alignItems: "center",
            backgroundColor: " #F9F9F9",
          }}
        >
          <Navbar color={"#160761"} />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "80%",
              flexDirection: "row-reverse",
              marginTop: 95,
            }}
          >
            <div
              className="main-Div-image"
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: 100,
              }}
            >
              <img className="image" alt="Image" src={career} />
            </div>

            <div style={{ marginTop: 100, width: "100%" }}>
              <p
                className="Main-Heading"
                style={{
                  color: "#01EFF7",
                  fontSize: "55px",
                  fontWeight: "600",
                }}
              >
                Why
                <br />
                Eastman Innovations?
              </p>
              <p
                className="Details"
                style={{ fontSize: "25px", fontWeight: "500", color: "#000" }}
              >
                Be part of our dynamic team at Eastman Innovations, where{" "}
                <br className="no-br" />
                experts in engineering, UI/UX design, data science, and{" "}
                <br className="no-br" />
                technology collaborate to create innovative solutions and{" "}
                <br className="no-br" />
                user-friendly products. Join the software revolution, grow{" "}
                <br className="no-br" />
                personally and professionally, and learn from talented peers{" "}
                <br className="no-br" />
                while tackling challenging problems.
              </p>
              <button
                onClick={() => handleClickScroll()}
                style={{
                  width: 190,
                  background:
                    "linear-gradient(264.78deg, #1E43DC -11.3%, #0BA2EA 129.77%)",
                  boxShadow: "0px 22px 11px -20px rgba(0, 0, 0, 0.15)",
                  borderRadius: 5,
                  color: "#ffffff",
                  textAlign: "center",
                  padding: "15px 26px",
                  fontSize: 18,
                  border: "none",
                  fontWeight: 700,
                }}
              >
                Apply Now
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          width: "100%",
          marginTop: "5%",
        }}
      >
        <div
          className=" Benefits-Div"
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "80%",
          }}
        >
          <div
            style={{
              marginTop: "5%",
              width: "80%",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <p
              className="Benefits"
              style={{
                color: "#01EFF7",
                fontSize: "40px",
                fontWeight: "700",
                margin: 0,
                marginBottom: 5,
              }}
            >
              Benefits
            </p>
            <p
              className="Main-Heading"
              style={{
                color: "#000",
                fontSize: "40px",
                fontWeight: "700",
                margin: 0,
                marginBottom: 10,
              }}
            >
              Why you Should Join Our <br />
              Awesome Team
            </p>
            <p
              className="Details"
              style={{
                color: "#000",
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "21px",
              }}
            >
              Join us for a rewarding career with numerous benefits: a dynamic
              work <br className="no-br" />
              environment, growth opportunities, continuous learning,
              competitive <br className="no-br" />
              compensation, comprehensive benefits, and a supportive team that
              values <br className="no-br" />
              diversity, creativity, and celebrates success. Together, we shape
              the future.
            </p>
          </div>
          <div
            className="Should-join-Us"
            style={{
              width: "80%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <div
              className="team-Work-Div"
              style={{
                display: "flex",
              }}
            >
              <div
                style={{
                  marginRight: 30,
                }}
              >
                <div
                  style={{
                    backgroundColor: "#E7F2FF",
                    padding: 20,
                    width: 77,
                  }}
                >
                  <img src={avatar} />
                </div>
                <p
                  style={{
                    fontStyle: "normal",
                    fontWeight: "700",
                    fontSize: 18,
                    // lineHeight: 27,
                    color: "#001833",
                    marginTop: "1rem",
                  }}
                >
                  Team work
                </p>
                <p
                  style={{
                    fontStyle: "normal",
                    fontWeight: 300,
                    fontSize: 14,
                    // lineHeight: 24,
                    color: "#001833",
                  }}
                >
                  the catalyst for collective success, where{" "}
                  <br className="no-br" />
                  collaboration, creativity, and effective{" "}
                  <br className="no-br" />
                  communication drive remarkable <br className="no-br" />
                  achievements.
                </p>
              </div>
              <div>
                <div
                  style={{
                    backgroundColor: "#F1F7E8",
                    padding: 20,
                    width: 77,
                  }}
                >
                  <img src={pause} />
                </div>
                <p
                  style={{
                    fontStyle: "normal",
                    fontWeight: "700",
                    fontSize: 18,
                    // lineHeight: 27,
                    color: "#001833",
                    marginTop: "1rem",
                  }}
                >
                  Secured Future
                </p>
                <p
                  style={{
                    fontStyle: "normal",
                    fontWeight: 300,
                    fontSize: 14,
                    // lineHeight: 24,
                    color: "#001833",
                  }}
                >
                  providing peace of mind through robust{" "}
                  <br className="no-br" />
                  protection and advanced safeguards for{" "}
                  <br className="no-br" />
                  enhanced data security.{" "}
                </p>
              </div>
            </div>
            <div
              className="team-Work-Div"
              style={{
                display: "flex",
              }}
            >
              <div
                style={{
                  marginRight: 30,
                }}
              >
                <div
                  style={{
                    backgroundColor: "#EFF2F5",
                    padding: 20,
                    width: 85,
                  }}
                >
                  <img src={cap} />
                </div>
                <p
                  style={{
                    fontStyle: "normal",
                    fontWeight: "700",
                    fontSize: 18,
                    // lineHeight: 27,
                    color: "#001833",
                    marginTop: "1rem",
                  }}
                >
                  Learning Technologies
                </p>
                <p
                  style={{
                    fontStyle: "normal",
                    fontWeight: 300,
                    fontSize: 14,
                    // lineHeight: 24,
                    color: "#001833",
                  }}
                >
                  Empowering individuals with innovative{" "}
                  <br className="no-br" />
                  tools and digital platforms to foster <br className="no-br" />
                  knowledge acquisition, skill development,{" "}
                  <br className="no-br" />
                  and lifelong learning.{" "}
                </p>
              </div>
              <div>
                <div
                  style={{
                    backgroundColor: "#FFEEED",
                    padding: 20,
                    width: 77,
                  }}
                >
                  <img src={rank} />
                </div>
                <p
                  style={{
                    fontStyle: "normal",
                    fontWeight: "700",
                    fontSize: 18,
                    // lineHeight: 27,
                    color: "#001833",
                    marginTop: "1rem",
                  }}
                >
                  Upgrate Skills
                </p>
                <p
                  style={{
                    fontStyle: "normal",
                    fontWeight: 300,
                    fontSize: 14,
                    // lineHeight: 24,
                    color: "#001833",
                  }}
                >
                  Unlocking personal and professional <br className="no-br" />
                  growth by acquiring new knowledge, <br className="no-br" />
                  honing abilities, and staying ahead <br className="no-br" />
                  in an ever-evolving landscape.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          width: "100%",
          height: 671,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "80%",
            flexDirection: "row-reverse",
          }}
        >
          <div
            style={{
              width: "600px",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <p
              className="Benefits"
              style={{
                color: "#01EFF7",
                fontSize: "40px",
                fontWeight: "700",
                margin: 0,
                marginBottom: 5,
              }}
            >
              We are hiring
            </p>
            <p
              className="Main-Heading"
              style={{
                color: "#000",
                fontSize: "40px",
                fontWeight: "700",
                margin: 0,
                marginBottom: 10,
              }}
            >
              come and join our team
            </p>
            <p
              className="Details"
              style={{ color: "#000", fontSize: "20px", fontWeight: "400" }}
            >
              Join our team and be a part of something <br className="no-br" />
              extraordinary as we seek talented individuals{" "}
              <br className="no-br" />
              to make an impact and contribute to our <br className="no-br" />
              innovative solutions.
            </p>
            <button
              onClick={() => handleClickScroll()}
              style={{
                width: 190,
                background:
                  "linear-gradient(264.78deg, #1E43DC -11.3%, #0BA2EA 129.77%)",
                boxShadow: "0px 22px 11px -20px rgba(0, 0, 0, 0.15)",
                borderRadius: 5,
                color: "#ffffff",
                textAlign: "center",
                padding: "15px 26px",
                fontSize: 18,
                border: "none",
                fontWeight: 700,
              }}
            >
              Apply Now
            </button>
          </div>
          <div
            className="main-Div-image"
            style={{
              width: "600px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img className="image" alt="Image" src={hiring} />
          </div>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          backgroundColor: "#F9F9F9",
          justifyContent: "center",
          width: "100%",
          height: 700,
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "80%",
            flexDirection: "column",
            marginTop: "4%",
          }}
        >
          <p
            style={{
              fontStyle: "normal",
              fontWeight: "700",
              fontSize: 40,
              textAlign: "center",
              color: " #001833",
            }}
          >
            Career Openings
          </p>
          <p
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: 14,
              textAlign: "center",
            }}
          >
            We’re always looking for creative, talented self-starters to join
            the Eastman <br className="career-brtag" />
            Innovations. Check out our open roles below and fill out an
            application.
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              width: "90%",
              flexWrap: "wrap",
            }}
          >
            <p
              className="Career-Openings"
              style={{
                fontWeight: "600",
                fontSize: 18,
                textTransform: "uppercase",
                color: "#001833",
                cursor: "pointer",
              }}
            >
              HR & Admin
            </p>
            <p
              className="Career-Openings"
              style={{
                fontWeight: "600",
                fontSize: 18,
                textTransform: "uppercase",
                color: "#001833",
                cursor: "pointer",
              }}
            >
              Engineering
            </p>
            <p
              className="Career-Openings"
              style={{
                fontWeight: "600",
                fontSize: 18,
                textTransform: "uppercase",
                color: "#001833",
                cursor: "pointer",
              }}
            >
              Support
            </p>
            <p
              className="Career-Openings"
              style={{
                fontWeight: "600",
                fontSize: 18,
                textTransform: "uppercase",
                color: "#001833",
                cursor: "pointer",
              }}
            >
              Design
            </p>
            <p
              className="Career-Openings"
              style={{
                fontWeight: "600",
                fontSize: 18,
                textTransform: "uppercase",
                color: "#001833",
                cursor: "pointer",
              }}
            >
              Digital Marketing
            </p>
          </div>
          <div
            className="Jobs"
            style={{
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: 36,
              color: "#2D3748",
              marginTop: "10%",
              textAlign: "center",
            }}
          >
            <p>No Job Avaliable.</p>
          </div>
        </div>
      </div>
      <div
        className="footer"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          width: "100%",
          background:
            "linear-gradient(269.87deg, #042573 -2.21%, #32175E 103.58%)",
          paddingTop: 60,
        }}
      >
        <div
          className="Footer-components"
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "80%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div>
              <img
                onClick={() => navigate("/")}
                src={icon}
                style={{ cursor: "pointer", marginLeft: -10, marginBottom: 10 }}
              />
              <p
                style={{
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: 14,
                  color: "#FFFFFF",
                  cursor: "default",
                }}
              >
                Get Free Estimate
              </p>
              <a
                className="Email-font"
                style={{
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: 16,
                  color: "#FFFFFF",
                  textDecoration: "none",
                }}
                href="https://mail.google.com/mail/?view=cm&fs=1&to=ehsan@eastmanenterprises.com"
                target="_blank"
              >
                ehsan@eastmanenterprises.com
              </a>
            </div>

            <div
              className="Contect-Number"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <img width="20px" height="20px" src={phone} />
              <a
                href="tel:+923044243496"
                style={{
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: 16,
                  color: "#FFFFFF",
                  margin: 0,
                  textDecoration: "none",
                  marginLeft: 20,
                }}
              >
                +92 304 4243496
              </a>
            </div>
            <a
              style={{ marginBottom: 50 }}
              href="https://pk.linkedin.com/company/eastman-enterprises-pk"
              target="_blank"
            >
              <img
                className="linked"
                height="30px"
                width="30px"
                src={linkdin}
              />
            </a>
          </div>

          <div>
            <p
              style={{
                fontStyle: "normal",
                fontWeight: 700,
                fontSize: 18,
                marginBottom: 30,
                color: "#FFFFFF",
                cursor: "default",
              }}
            >
              Company
            </p>
            <p
              style={{
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: 14,

                color: "#FFFFFF",
                marginBottom: 22,
                opacity: 0.78,
                cursor: "pointer",
              }}
              onClick={() => navigate("/")}
            >
              Home
            </p>
            <p
              style={{
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: 14,
                cursor: "pointer",
                color: "#FFFFFF",
                marginBottom: 22,
                opacity: 0.78,
              }}
              onClick={() => navigate("/technologies")}
            >
              Technologies
            </p>
            <p
              style={{
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: 14,
                cursor: "pointer",
                color: "#FFFFFF",
                marginBottom: 22,
                opacity: 0.78,
              }}
              onClick={() => navigate("/portfolio")}
            >
              Portfolio
            </p>
            <p
              style={{
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: 14,
                cursor: "pointer",
                color: "#FFFFFF",
                marginBottom: 22,
                opacity: 0.78,
              }}
              onClick={() => navigate("/career")}
            >
              Career
            </p>
          </div>

          <div>
            <p
              style={{
                fontStyle: "normal",
                fontWeight: 700,
                fontSize: 18,
                marginBottom: 30,
                color: "#FFFFFF",
                cursor: "default",
              }}
            >
              Services
            </p>
            <p
              style={{
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: 14,

                color: "#FFFFFF",
                marginBottom: 22,
                opacity: 0.78,
                cursor: "default",
              }}
            >
              Android App Development
            </p>
            <p
              style={{
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: 14,

                color: "#FFFFFF",
                marginBottom: 22,
                opacity: 0.78,
                cursor: "default",
              }}
            >
              AngularJs Development
            </p>
            <p
              style={{
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: 14,
                cursor: "default",
                color: "#FFFFFF",
                marginBottom: 22,
                opacity: 0.78,
              }}
            >
              PHP Development
            </p>
            <p
              style={{
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: 14,
                cursor: "default",
                color: "#FFFFFF",
                marginBottom: 22,
                opacity: 0.78,
              }}
            >
              CodeIgniter Development
            </p>
            <p
              style={{
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: 14,
                cursor: "default",
                color: "#FFFFFF",
                marginBottom: 22,
                opacity: 0.78,
              }}
            >
              Net Development
            </p>
            <p
              style={{
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: 14,
                cursor: "default",
                color: "#FFFFFF",
                marginBottom: 22,
                opacity: 0.78,
              }}
            >
              Augmented Reality
            </p>
            <p
              style={{
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: 14,
                cursor: "default",
                color: "#FFFFFF",
                marginBottom: 22,
                opacity: 0.78,
              }}
            >
              AWS Development
            </p>
          </div>
          <ContactUs />
        </div>
      </div>
    </div>
  );
};

export default CareerDesktop;
