import React, { useState, useEffect, useRef } from "react";
import emailjs from "@emailjs/browser";
import "./Home.css";

const ContactUs = () => {
  const form = useRef();
  const [successMessage, setSuccessMessage] = useState("");
  const [name, setName] = useState("");
  const [email, setemail] = useState("");
  const [Message, setmessage] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const validateInputs = () => {
    // Validate inputs and set the disabled state of the button accordingly
    setIsButtonDisabled(
      name.length <= 3 || Message.length <= 3 || email.length <= 5
    );
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_kpjsqdk",
        "template_0ll83b2",
        form.current,
        "ovAClfrkuNYnt26f-"
      )
      .then(
        (result) => {
          console.log(result.text);
          console.log("message sent");
          setSuccessMessage("Email sent successfully!");
          resetForm();
        },
        (error) => {
          console.log(error.text);
        }
      );
    const resetForm = () => {
      form.current.reset();
      validateInputs();
    };
  };
  useEffect(() => {
    validateInputs();

    if (successMessage) {
      const timeout = setTimeout(() => {
        setSuccessMessage("");
      }, 10000);
      return () => clearTimeout(timeout);
    }
  }, [successMessage, name, email, Message]);
  return (
    <div className="Footer-Fields">
      {successMessage && (
        <div className="success-message">
          {" "}
          <span style={{ color: "#fff" }}>{successMessage}</span>
        </div>
      )}
      <form ref={form} onSubmit={sendEmail}>
        <div
          className="Name-Field"
          style={{
            display: "flex",
            justifyContent: "space-around",
            margin: "25px 0",
          }}
        >
          <div>
            <label
              style={{
                display: "flex",
                flexDirection: "column",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: 14,
                textTransform: "capitalize",
                color: "#fff",
              }}
            >
              Name
            </label>
            <input
              required
              onChange={(e) => setName(e.target.value)}
              maxLength={15}
              className="name-Input"
              style={{
                marginTop: 15,
                width: "95%",
                height: 40,
                background: "#042573",
                border: "1px solid #EBF0F9",
                borderRadius: 5,
                paddingLeft: 5,
                color: "#fff",
              }}
              placeholder="Enter your name"
              type="text"
              name="user_name"
            />
          </div>
          <div>
            <label
              className="input-Contact"
              style={{
                display: "flex",
                flexDirection: "column",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: 14,
                textTransform: "capitalize",
                color: "#fff",
              }}
            >
              Email
            </label>
            <input
              required
              onChange={(e) => setemail(e.target.value)}
              maxLength={20}
              className="contact-Input"
              style={{
                marginTop: 15,
                width: "100%",
                height: 40,
                background: "#042573",
                border: "1px solid #EBF0F9",
                borderRadius: 5,
                paddingLeft: 5,
                color: "#fff",
              }}
              type="email"
              placeholder="Enter your Email"
              name="user_Email"
            />
          </div>
        </div>
        <div className="message_section">
          <label
            style={{
              display: "flex",
              flexDirection: "column",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: 14,
              textTransform: "capitalize",
              color: "#fff",
              marginTop: 15,
            }}
          >
            Message
          </label>
          <textarea
            onChange={(e) => setmessage(e.target.value)}
            required
            className="Email-input"
            style={{
              marginTop: 15,
              width: "100%",
              height: 150,
              background: "#042573",
              border: "1px solid #EBF0F9",
              borderRadius: 5,
              paddingLeft: 5,
              color: "#fff",
              resize: "none",
            }}
            placeholder="Enter your Message"
            name="message"
            maxLength={318}
          />
          <input
            disabled={
              isButtonDisabled ||
              name.length <= 3 ||
              Message.length <= 3 ||
              email.length <= 5
            }
            className="Footer-Button"
            type="submit"
            style={{
              width: "177px",
              height: "48px",
              background:
                "linear-gradient(264.78deg, #1E43DC -11.3%, #0BA2EA 129.77%)",
              boxShadow: "0px 22px 11px -20px rgba(0, 0, 0, 0.15)",
              borderRadius: 5,
              color: "#ffffff",
              textAlign: "center",
              fontSize: 14,
              border: "none",
              fontWeight: 700,
              marginTop: 10,
              cursor: "pointer",
              marginBottom: 50,
            }}
            value="Send Message"
          />
        </div>
      </form>
    </div>
  );
};
export default ContactUs;
