import React from "react";
import Slider from "react-slick";
import "./Portfollio.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../../App.css";

import careClinic from "../../../assets/careClinic.png";
import careClinicLogo from "../../../assets/careClinicLogo.png";
import php from "../../../assets/php.png";
import CodeIgnitter from "../../../assets/CodeIgnitter.png";

import nodejs from "../../../assets/nodejs.png";
import reactjs from "../../../assets/react js.png";
import sealpack from "../../../assets/sealpact.png";
import SealPactlogo from "../../../assets/SealPact_logo.png";

import popfamelogo from "../../../assets/PopfameLogo.png";
import popfame from "../../../assets/popfam-web.png";

import CustomPrevArrow from "../../../assets/CustomPrevArrow.png";
import CustomNextArrow from "../../../assets/CustomNextArrow.png";

import facebook from "../../../assets/facebook.png";
import instagram from "../../../assets/instagram.png";
import linkdin from "../../../assets/linkdin.png";
import phone from "../../../assets/phone.png";
import twitter from "../../../assets/twitter.png";
import youtube from "../../../assets/youtube.png";
import icon from "../../../assets/Icon.png";
import ContactUs from "../Home/ContactUs";

import { useNavigate } from "react-router-dom";

const WebDev = () => {
  const navigate = useNavigate();

  const FirstSliderLink = [
    {
      Tech: "ReactJs",
    },
    {
      Tech: "VueJs",
    },
    {
      Tech: "NextJs",
    },
    {
      Tech: "Python",
    },
    {
      Tech: "Node.js",
    },
    {
      Tech: "PHP",
    },
  ];
  const secoundSliderLink = [
    {
      Tech: "Solar",
    },
    {
      Tech: "Sports",
    },
    {
      Tech: "Artificial Intelligence",
    },
    {
      Tech: "Medical",
    },
    {
      Tech: "Commercial services",
    },
    {
      Tech: "Codeigniter",
    },
  ];
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    prevArrow: <img src={CustomNextArrow} />,
    nextArrow: <img src={CustomPrevArrow} />,
    responsive: [
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 426,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const setting2 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    prevArrow: <img src={CustomNextArrow} />,
    nextArrow: <img src={CustomPrevArrow} />,
    responsive: [
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 426,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div style={{ width: "80%", margin: "0 auto", marginTop: 140 }}>
        <Slider {...settings}>
          {FirstSliderLink.map((links, Index) => {
            return (
              <>
                <div
                  style={{
                    borderRadius: 5,
                  }}
                  key={Index}
                >
                  <p
                    className="Text"
                    style={{
                      textAlign: "center",
                      fontStyle: "normal",
                      fontWeight: 500,
                      fontSize: 20,
                      lineHeight: "125.5%;",
                      color: " #2D3748",
                      margin: 0,
                    }}
                  >
                    {links.Tech}
                  </p>
                </div>
              </>
            );
          })}
        </Slider>
        <div className="slider-2" style={{ marginTop: 40 }}>
          <Slider {...setting2}>
            {secoundSliderLink.map((links, Index) => {
              return (
                <>
                  <div
                    style={{
                      borderRadius: 5,
                    }}
                    key={Index}
                  >
                    <p
                      className="Text"
                      style={{
                        textAlign: "center",
                        fontStyle: "normal",
                        fontWeight: 500,
                        fontSize: 20,
                        lineHeight: "125.5%;",
                        color: " #2D3748",
                        margin: 0,
                      }}
                    >
                      {links.Tech}
                    </p>
                  </div>
                </>
              );
            })}
          </Slider>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "70px",
            }}
          ></div>
        </div>
      </div>

      <div
        className="web-dev"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: "100%",
          height: 671,
          alignItems: "center",
          marginTop: 50,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "80%",
          }}
        >
          <div
            className="img-div"
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img className="image" alt="Image" src={careClinic} />
          </div>

          <div
            className="Projects-Details"
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              marginLeft: 50,
              flexDirection: "column",
              marginTop: 65,
            }}
          >
            <img width="181px" height="58px" src={careClinicLogo} />
            <p
              className="Mian-Detail "
              style={{ color: "#12062E", fontSize: "20px", fontWeight: "400" }}
            >
              Our web app empowers patients to proactively manage their health
              conditions, promoting better health outcomes through support,
              treatment plan adherence, and patient engagement
            </p>
            <a href="https://careclinic.io/" target="_blank">
              <button
                style={{
                  width: 150,
                  background:
                    "linear-gradient(264.78deg, #1E43DC -11.3%, #0BA2EA 129.77%)",
                  boxShadow: "0px 22px 11px -20px rgba(0, 0, 0, 0.15)",
                  borderRadius: 5,
                  color: "#ffffff",
                  textAlign: "center",
                  padding: "15px 26px",
                  fontSize: 15,
                  border: "none",
                  fontWeight: 700,
                }}
              >
                Check it out!
              </button>
            </a>

            {/* <a
              href="https://careclinic.io/"
              target="_blank"
              style={{
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: 20,
                lineHeight: "110.5%;",
                color: "#000",
              }}
            >
              https://careclinic.io/
            </a> */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                style={{
                  marginRight: 60,
                }}
                src={php}
              />
              <img src={CodeIgnitter} />
            </div>
          </div>
        </div>
        <div
          style={{
            width: "70%",
            height: 5,
            background:
              "linear-gradient(269.87deg, #042573 -2.21%, #32175E 103.58%)",
            borderRadius: 2,
            marginTop: 40,
          }}
        ></div>
      </div>
      <div
        className="web-dev-1"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: "100%",
          height: 671,
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row-reverse",
            justifyContent: "space-between",
            width: "80%",
          }}
        >
          <div
            className="img-div"
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img className="image" alt="Image" src={sealpack} />
          </div>

          <div
            className="Projects-Details"
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              marginRight: 50,
              flexDirection: "column",
            }}
          >
            <img width="181px" height="58px" src={SealPactlogo} />
            <p
              className="Mian-Detail "
              style={{ color: "#12062E", fontSize: "20px", fontWeight: "400" }}
            >
              Our platform addresses the growing need to digitise seals for
              architects and engineers. With more design professionals working
              remotely and the demand for electronic documents, our app{" "}
              streamlines the process, providing a convenient solution for
              digitising seals.
            </p>
            <a href="https://sealpact.com" target="_blank">
              <button
                style={{
                  width: 150,
                  background:
                    "linear-gradient(264.78deg, #1E43DC -11.3%, #0BA2EA 129.77%)",
                  boxShadow: "0px 22px 11px -20px rgba(0, 0, 0, 0.15)",
                  borderRadius: 5,
                  color: "#ffffff",
                  textAlign: "center",
                  padding: "15px 26px",
                  fontSize: 15,
                  border: "none",
                  fontWeight: 700,
                }}
              >
                Check it out!
              </button>
            </a>

            {/* <a
              href="https://sealpact.com"
              target="_blank"
              style={{
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: 20,
                lineHeight: "110.5%;",
                color: "#000",
              }}
            >
              https://sealpact.com
            </a> */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: 15,
              }}
            >
              <img
                style={{
                  marginRight: 60,
                }}
                src={reactjs}
              />
              <img src={nodejs} />
            </div>
          </div>
        </div>
        <div
          style={{
            width: "70%",
            height: 5,
            background:
              "linear-gradient(269.87deg, #042573 -2.21%, #32175E 103.58%)",
            borderRadius: 2,
            marginTop: 40,
          }}
        ></div>
      </div>

      <div
        className="web-dev-2"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: "100%",
          height: 671,
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "80%",
          }}
        >
          <div
            className="img-div"
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img className="image" alt="Image" src={popfame} />
          </div>

          <div
            className="Projects-Details"
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              marginLeft: 50,
              flexDirection: "column",
            }}
          >
            <img width="120px" height="110px" src={popfamelogo} />
            <p
              className="Mian-Detail "
              style={{
                color: "#12062E",
                fontSize: "20px",
                fontWeight: "400",
                marginTop: 10,
              }}
            >
              Quality Home Services app offers on-demand{" "}
              <br className="no-br" />
              landscaping services, making it easier, <br className="no-br" />
              quicker, and more efficient to find trusted{" "}
              <br className="no-br" />
              professionals for your home needs.
            </p>
            <a href="https://thepopfame.com/" target="_blank">
              <button
                style={{
                  width: 150,
                  background: "#673724",
                  boxShadow: "0px 22px 11px -20px rgba(0, 0, 0, 0.15)",
                  borderRadius: 5,
                  color: "#ffffff",
                  textAlign: "center",
                  padding: "15px 26px",
                  fontSize: 15,
                  border: "none",
                  fontWeight: 700,
                }}
              >
                Check it out!
              </button>
            </a>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: 15,
              }}
            >
              <img
                style={{
                  marginRight: 60,
                }}
                src={reactjs}
              />
              <img src={nodejs} />
            </div>
          </div>
        </div>
        <div
          style={{
            width: "70%",
            height: 5,
            background:
              "linear-gradient(269.87deg, #042573 -2.21%, #32175E 103.58%)",
            borderRadius: 2,
            marginTop: 40,
          }}
        ></div>
      </div>
      {/* Footer */}
      <div
        className="footer"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          width: "100%",
          background:
            "linear-gradient(269.87deg, #042573 -2.21%, #32175E 103.58%)",
          paddingTop: 60,
        }}
      >
        <div
          className="Footer-components"
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "80%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div>
              <img
                onClick={() => navigate("/")}
                src={icon}
                style={{
                  cursor: "pointer",
                  marginBottom: 10,
                  marginRight: -10,
                }}
              />
              <p
                style={{
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: 14,
                  color: "#FFFFFF",
                  cursor: "default",
                }}
              >
                Get Free Estimate
              </p>
              <a
                className="Email-font"
                style={{
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: 16,
                  color: "#FFFFFF",
                  textDecoration: "none",
                }}
                href="https://mail.google.com/mail/?view=cm&fs=1&to=ehsan@eastmanenterprises.com"
                target="_blank"
              >
                ehsan@eastmanenterprises.com
              </a>
            </div>

            <div
              className="Contect-Number"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <img width="20px" height="20px" src={phone} />
              <a
                href="tel:+923044243496"
                style={{
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: 16,
                  color: "#FFFFFF",
                  margin: 0,
                  textDecoration: "none",
                  marginLeft: 20,
                }}
              >
                +92 304 4243496
              </a>
            </div>
            <a
              style={{ marginBottom: 50 }}
              href="https://pk.linkedin.com/company/eastman-enterprises-pk"
              target="_blank"
            >
              <img
                className="linked"
                height="30px"
                width="30px"
                src={linkdin}
              />
            </a>
          </div>

          <div>
            <p
              style={{
                fontStyle: "normal",
                fontWeight: 700,
                fontSize: 18,
                marginBottom: 30,
                color: "#FFFFFF",
                cursor: "default",
              }}
            >
              Company
            </p>
            <p
              style={{
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: 14,

                color: "#FFFFFF",
                marginBottom: 22,
                opacity: 0.78,
                cursor: "pointer",
              }}
              onClick={() => navigate("/")}
            >
              Home
            </p>
            <p
              style={{
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: 14,
                cursor: "pointer",
                color: "#FFFFFF",
                marginBottom: 22,
                opacity: 0.78,
              }}
              onClick={() => navigate("/technologies")}
            >
              Technologies
            </p>
            <p
              style={{
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: 14,
                cursor: "pointer",
                color: "#FFFFFF",
                marginBottom: 22,
                opacity: 0.78,
              }}
              onClick={() => navigate("/portfolio")}
            >
              Portfolio
            </p>
            <p
              style={{
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: 14,
                cursor: "pointer",
                color: "#FFFFFF",
                marginBottom: 22,
                opacity: 0.78,
              }}
              onClick={() => navigate("/career")}
            >
              Career
            </p>
          </div>

          <div>
            <p
              style={{
                fontStyle: "normal",
                fontWeight: 700,
                fontSize: 18,
                marginBottom: 30,
                color: "#FFFFFF",
                cursor: "default",
              }}
            >
              Services
            </p>
            <p
              style={{
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: 14,

                color: "#FFFFFF",
                marginBottom: 22,
                opacity: 0.78,
                cursor: "default",
              }}
            >
              Android App Development
            </p>
            <p
              style={{
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: 14,

                color: "#FFFFFF",
                marginBottom: 22,
                opacity: 0.78,
                cursor: "default",
              }}
            >
              AngularJs Development
            </p>
            <p
              style={{
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: 14,
                cursor: "default",
                color: "#FFFFFF",
                marginBottom: 22,
                opacity: 0.78,
              }}
            >
              PHP Development
            </p>
            <p
              style={{
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: 14,
                cursor: "default",
                color: "#FFFFFF",
                marginBottom: 22,
                opacity: 0.78,
              }}
            >
              CodeIgniter Development
            </p>
            <p
              style={{
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: 14,
                cursor: "default",
                color: "#FFFFFF",
                marginBottom: 22,
                opacity: 0.78,
              }}
            >
              Net Development
            </p>
            <p
              style={{
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: 14,
                cursor: "default",
                color: "#FFFFFF",
                marginBottom: 22,
                opacity: 0.78,
              }}
            >
              Augmented Reality
            </p>
            <p
              style={{
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: 14,
                cursor: "default",
                color: "#FFFFFF",
                marginBottom: 22,
                opacity: 0.78,
              }}
            >
              AWS Development
            </p>
          </div>
          <ContactUs />
        </div>
      </div>
    </>
  );
};

export default WebDev;
