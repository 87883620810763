import React, { useState, useEffect } from "react";
import "./style.css";
import "./Home.css";
import icon from "../../../assets/Icon.png";
import linkdin from "../../../assets/linkdin.png";

import slice1 from "../../../Slice1.png";
import { useNavigate } from "react-router-dom";

import { Link } from "react-router-dom";
import facebook from "../../../assets/facebook.png";
import instagram from "../../../assets/instagram.png";

import phone from "../../../assets/phone.png";
import Form from "react-bootstrap/Form";
import twitter from "../../../assets/twitter.png";
import youtube from "../../../assets/youtube.png";
import Example from "./Slider/example.js";

import dot1 from "../../../assets/dot1.png";
import dot2 from "../../../assets/dote2.png";
import Ellipse1 from "../../../assets/Ellipse1.png";
import Ellipse2 from "../../../assets/Ellipse2.png";
import Ellipse3 from "../../../assets/Ellipse3.png";
import Ellipse4 from "../../../assets/Ellipse4.png";
import Ellipse5 from "../../../assets/Ellipse5.png";
import Ellipse6 from "../../../assets/Ellipse7.png";
import Navbar from "../Navbar/Navbar";
import ContactUs from "./ContactUs";
const HomeDesktop = () => {
  const navigate = useNavigate();
  const [jsonData, setJsonData] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [navigate]);

  const handleInputChange = (event) => {
    const { value } = event.target;
    setJsonData(value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    try {
      const parsedData = JSON.parse(jsonData);
      console.log(parsedData);
    } catch (error) {
      console.error("Invalid JSON:", error.message);
    }
  };

  return (
    <div
      style={{
        backgroundColor: "white",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        marginTop: -1,
      }}
    >
      <div
        style={{
          width: "100%",
          height: 980,
          background:
            "linear-gradient(269.87deg, rgb(50, 23, 94) -2.21%, rgb(6, 38, 175) 103.58%)",
        }}
      >
        <div
          className="Landing-Page"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            width: "100%",
            height: 980,
            backgroundSize: "cover",
            backgroundImage: `url("https://anima-uploads.s3.amazonaws.com/projects/648b449e01a3e041d7fd0d49/releases/648b46a0e4074b96d3116b9c/img/artboard-2-100-1.png")`,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              width: "100%",
              alignItems: "center",
            }}
          >
            <Navbar />
            <div
              className="landing-Page"
              style={{ marginTop: 200, width: "80%" }}
            >
              <p
                className="main-Heading"
                style={{
                  color: "#01EFF7",
                  fontSize: "55px",
                  fontWeight: "600",
                }}
              >
                Transforming your
                <br /> Ideas into Reality
              </p>
              <p
                className="Experties"
                style={{
                  fontSize: "25px",
                  fontWeight: "600",
                  color: "white",
                  lineHeight: "30px",
                }}
              >
                We are experts in translating your ideas into tangible realities
                <br className="br-HomeTitle" />
                using innovative technologies and tailored strategies. With our
                <br className="br-HomeTitle" />
                dedicated team's comprehensive support, from ideation{" "}
                <br className="br-HomeTitle" />
                to execution, we bring your vision to life.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          width: "100%",
          height: 980,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "80%",
          }}
        >
          <div
            className="Image-Div"
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              style={{ width: "80%" }}
              className="image"
              alt="Image"
              src="https://anima-uploads.s3.amazonaws.com/projects/648b449e01a3e041d7fd0d49/releases/648b46a0e4074b96d3116b9c/img/image-1.png"
            />
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              marginTop: 60,
            }}
          >
            <p
              style={{
                color: "#01EFF7",
                fontSize: "30px",
                fontWeight: "600",
                margin: 0,
                marginBottom: 10,
              }}
            >
              Build Quality
            </p>
            <p
              className="text"
              style={{
                color: "#2D3748",
                fontSize: "55px",
                fontWeight: "700",
                margin: 0,
                marginBottom: 40,
              }}
            >
              Our Special Feature
            </p>
            <p
              className="Texxt"
              style={{
                color: "#12062E",
                fontSize: "25px",
                fontWeight: "400",
                marginBottom: 40,
              }}
            >
              At Eastman, our exceptional special feature is our unwavering
              commitment to customer satisfaction. We stand out by understanding
              your unique needs and providingtailored solutions.
            </p>
            <div
              className="features"
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "500px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <img
                  style={{
                    backgroundColor: "#01EFF7",
                    borderRadius: "50px",
                    height: "45px",
                    width: "45px",
                    padding: 5,
                  }}
                  className="free-quality-icon"
                  alt="Free quality icon"
                  src="https://anima-uploads.s3.amazonaws.com/projects/648b449e01a3e041d7fd0d49/releases/648b46a0e4074b96d3116b9c/img/free-quality-icon-3602-thumb-1@2x.png"
                />
                <p
                  className="Features-Type-Text"
                  style={{
                    fontSize: "16px",
                    fontWeight: "700",
                    color: "#0B163F",
                  }}
                >
                  Quality First
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <img
                  style={{
                    backgroundColor: "#01EFF7",
                    borderRadius: "50px",
                    height: "45px",
                    width: "45px",
                    padding: 5,
                  }}
                  className="handshake"
                  alt="Handshake"
                  src="https://anima-uploads.s3.amazonaws.com/projects/648b449e01a3e041d7fd0d49/releases/648b46a0e4074b96d3116b9c/img/handshake-1@2x.png"
                />
                <p
                  className="Features-Type-Text"
                  style={{
                    fontSize: "16px",
                    fontWeight: "700",
                    color: "#0B163F",
                  }}
                >
                  Communication
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <img
                  style={{
                    backgroundColor: "#01EFF7",
                    borderRadius: "50px",
                    height: "45px",
                    width: "45px",
                    padding: 5,
                  }}
                  className="handshake"
                  alt="Handshake"
                  src="https://anima-uploads.s3.amazonaws.com/projects/648b449e01a3e041d7fd0d49/releases/648b46a0e4074b96d3116b9c/img/1600130-1@2x.png"
                />
                <p
                  className="Features-Type-Text"
                  style={{
                    fontSize: "16px",
                    fontWeight: "700",
                    color: "#0B163F",
                  }}
                >
                  Reliability
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          width: "100%",
          height: 980,
          backgroundSize: "cover",
          backgroundImage: `url("https://anima-uploads.s3.amazonaws.com/projects/648b449e01a3e041d7fd0d49/releases/648b46a0e4074b96d3116b9c/img/artboard-4-8-1.png")`,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            width: "80%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <p
            className="text"
            style={{
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: 55,
              textAlign: "center",
              marginBottom: 5,
              color: "#fff",
              lineHeight: "130%",
            }}
          >
            <span style={{ color: "#01EFF7" }}>We deliver </span>best quality{" "}
            <br className="br-no" />
            software for your business
          </p>
          <p
            className="Quality-Insurance"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: 25,
              color: "#fff",
              textAlign: "center",
              lineHeight: "130%",
              marginTop: 30,
            }}
          >
            Trust us to deliver high-quality, tailored software solutions that
            fuel your organisation's growth and success through cutting-edge
            technology, industry best practices, and a strong focus on
            performance, security, and quality assurance.
          </p>
          <button
            onClick={() => navigate("/lets-talk")}
            style={{
              width: 190,
              marginTop: 31,
              background:
                "linear-gradient(264.78deg, #1E43DC -11.3%, #0BA2EA 129.77%)",
              boxShadow: "0px 22px 11px -20px rgba(0, 0, 0, 0.15)",
              borderRadius: 5,
              color: "#ffffff",
              textAlign: "center",
              padding: "15px 26px",
              fontSize: 18,
              border: "none",
              fontWeight: 700,
            }}
          >
            Let's Talk
          </button>
        </div>
      </div>
      <div
        className="Build"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          width: "100%",
          height: 980,
          backgroundSize: "cover",
          backgroundImage: `url(${require("../../../Slice1.png")})`,
        }}
      />

      <div
        className="our-Goal"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          width: "100%",
          height: 530,
          background:
            "linear-gradient(269.87deg, #042573 -2.21%, #32175E 103.58%)",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            width: "80%",
            flexDirection: "column",
          }}
        >
          <img
            className="logo"
            width="85px"
            height="65px"
            alt="Logo"
            src={icon}
          />
          <p
            className="New"
            style={{
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: 55,
              color: "#FFFFFF",
              margin: 0,
            }}
          >
            To New Heights
          </p>

          <div
            className="goal_div"
            style={{
              width: "80%",
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <div style={{ width: "80%" }}>
              <p
                className="Our_Goal_brief"
                style={{
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: 25,
                  marginTop: 0,
                  color: "#FFFFFF",
                }}
              >
                Our goal is to formulate long-term strategic partnerships with
                businesses through shared leadership, advanced technology, and
                transparent relationships.
              </p>
            </div>
            <div className="bittion" style={{ width: "20%" }}>
              <button
                onClick={() => navigate("/lets-talk")}
                style={{
                  width: 226,
                  background:
                    "linear-gradient(264.78deg, #1E43DC -11.3%, #0BA2EA 129.77%)",
                  boxShadow: "0px 22px 11px -20px rgba(0, 0, 0, 0.15)",
                  borderRadius: 5,
                  color: "#ffffff",
                  textAlign: "center",
                  padding: "15px 26px",
                  fontSize: 18,
                  border: "none",
                  fontWeight: 700,
                }}
              >
                Let's Talk
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="slider">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            width: "100%",
            height: 980,
          }}
        >
          <div
            style={{
              display: "flex",
              width: "1280px",
              flexDirection: "column",
            }}
          >
            <p
              style={{
                color: "#01EFF7",
                fontSize: 30,
                fontStyle: "normal",
                fontWeight: 500,
                textAlign: "center",
                margin: 0,
                marginTop: "6%",
              }}
            >
              What the client says about us{" "}
            </p>

            <p
              style={{
                color: "#2D3748",
                textAlign: "center",
                fontSize: 55,
                fontStyle: "normal",
                fontWeight: 700,
                textAlign: "center",
                margin: 0,
              }}
            >
              Don't take our word for it. <br />
              trust our customer
            </p>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <img
                  style={{
                    marginLeft: -30,
                    borderRadius: "100px",
                  }}
                  width="136px"
                  height="136px"
                  src={Ellipse1}
                />
                <img
                  style={{
                    marginLeft: -50,
                    marginTop: 100,
                    borderRadius: "100px",
                  }}
                  width="93px"
                  height="93px"
                  src={Ellipse2}
                />
                <div>
                  <img
                    style={{
                      marginTop: 100,
                      marginLeft: 83,
                    }}
                    src={dot2}
                  />
                </div>
                <img
                  style={{
                    marginTop: -80,
                    marginLeft: 30,
                    borderRadius: "100px",
                  }}
                  width="105px"
                  height="105px"
                  src={Ellipse5}
                />
              </div>
              <Example />
              <div
                style={{
                  display: "flex",

                  flexDirection: "column",
                }}
              >
                <img
                  style={{
                    marginLeft: "auto",
                    borderRadius: "100px",
                  }}
                  width="94px"
                  height="94px"
                  src={Ellipse4}
                />
                <img
                  style={{
                    marginRight: 92,
                    marginTop: 50,
                  }}
                  src={dot1}
                />
                <img
                  style={{
                    marginLeft: "auto",
                    marginTop: 30,
                    borderRadius: 100,
                    objectFit: "cover",
                  }}
                  width="154px"
                  height="154px"
                  src={Ellipse6}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Footer */}
      <div
        className="footer"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          width: "100%",
          background:
            "linear-gradient(269.87deg, #042573 -2.21%, #32175E 103.58%)",
          paddingTop: 60,
        }}
      >
        <div
          className="Footer-components"
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "80%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div>
              <img
                onClick={() => navigate("/")}
                src={icon}
                style={{ cursor: "pointer", marginLeft: -10, marginBottom: 10 }}
              />
              <p
                style={{
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: 14,
                  color: "#FFFFFF",
                  cursor: "default",
                }}
              >
                Get Free Estimate
              </p>
              <a
                className="Email-font"
                style={{
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: 16,
                  color: "#FFFFFF",
                  textDecoration: "none",
                }}
                href="https://mail.google.com/mail/?view=cm&fs=1&to=ehsan@eastmanenterprises.com"
                target="_blank"
              >
                ehsan@eastmanenterprises.com
              </a>
            </div>

            <div
              className="Contect-Number"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <img width="20px" height="20px" src={phone} />
              <a
                href="tel:+923044243496"
                style={{
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: 16,
                  color: "#FFFFFF",
                  margin: 0,
                  textDecoration: "none",
                  marginLeft: 20,
                }}
              >
                +92 304 4243496
              </a>
            </div>
            <a
              style={{
                marginBottom: 50,
                display: "flex",
                textDecoration: "none",
              }}
              href="https://pk.linkedin.com/company/eastman-enterprises-pk"
              target="_blank"
            >
              <img
                className="linked"
                height="30px"
                width="30px"
                src={linkdin}
              />
              <p
                style={{
                  marginLeft: "5px",
                  color: "#fff",
                  fontWeight: 600,
                  fontSize: 16,
                  fontStyle: "normal",
                  marginTop: "10px",
                }}
              >
                linkedin.com/Eastman-Innovations
              </p>
            </a>
          </div>

          <div>
            <p
              style={{
                fontStyle: "normal",
                fontWeight: 700,
                fontSize: 18,
                marginBottom: 30,
                color: "#FFFFFF",
                cursor: "default",
              }}
            >
              Company
            </p>
            <p
              style={{
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: 14,

                color: "#FFFFFF",
                marginBottom: 22,
                opacity: 0.78,
                cursor: "pointer",
              }}
              onClick={() => navigate("/")}
            >
              Home
            </p>
            <p
              style={{
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: 14,
                cursor: "pointer",
                color: "#FFFFFF",
                marginBottom: 22,
                opacity: 0.78,
              }}
              onClick={() => navigate("/technologies")}
            >
              Technologies
            </p>
            <p
              style={{
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: 14,
                cursor: "pointer",
                color: "#FFFFFF",
                marginBottom: 22,
                opacity: 0.78,
              }}
              onClick={() => navigate("/portfolio")}
            >
              Portfolio
            </p>
            <p
              style={{
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: 14,
                cursor: "pointer",
                color: "#FFFFFF",
                marginBottom: 22,
                opacity: 0.78,
              }}
              onClick={() => navigate("/career")}
            >
              Career
            </p>
          </div>

          <div>
            <p
              style={{
                fontStyle: "normal",
                fontWeight: 700,
                fontSize: 18,
                marginBottom: 30,
                color: "#FFFFFF",
                cursor: "default",
              }}
            >
              Services
            </p>
            <p
              style={{
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: 14,

                color: "#FFFFFF",
                marginBottom: 22,
                opacity: 0.78,
                cursor: "default",
              }}
            >
              Android App Development
            </p>
            <p
              style={{
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: 14,

                color: "#FFFFFF",
                marginBottom: 22,
                opacity: 0.78,
                cursor: "default",
              }}
            >
              AngularJs Development
            </p>
            <p
              style={{
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: 14,
                cursor: "default",
                color: "#FFFFFF",
                marginBottom: 22,
                opacity: 0.78,
              }}
            >
              PHP Development
            </p>
            <p
              style={{
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: 14,
                cursor: "default",
                color: "#FFFFFF",
                marginBottom: 22,
                opacity: 0.78,
              }}
            >
              CodeIgniter Development
            </p>
            <p
              style={{
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: 14,
                cursor: "default",
                color: "#FFFFFF",
                marginBottom: 22,
                opacity: 0.78,
              }}
            >
              Net Development
            </p>
            <p
              style={{
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: 14,
                cursor: "default",
                color: "#FFFFFF",
                marginBottom: 22,
                opacity: 0.78,
              }}
            >
              Augmented Reality
            </p>
            <p
              style={{
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: 14,
                cursor: "default",
                color: "#FFFFFF",
                marginBottom: 22,
                opacity: 0.78,
              }}
            >
              AWS Development
            </p>
          </div>
          <ContactUs />
        </div>
      </div>
    </div>
  );
};

export default HomeDesktop;
