import React, { Component } from "react";
import VerticalCarousel from "./VerticalCarousel";
import uuidv4 from "uuid";
import { config } from "react-spring";
import Ellipse1 from "../../../../assets/Ellipse1.png";
import Ellipse2 from "../../../../assets/Ellipse2.png";
import Ellipse3 from "../../../../assets/Ellipse3.png";
import styled from "@emotion/styled";
const contentStyle = {
  display: "flex",
  justifyContent: "center",
};

let slides = [
  {
    key: 1,
    image: (
      <img
        style={{
          margin: "0 auto",
          marginTop: "-51px",
          display: "flex",
          borderRadius: "100px",
        }}
        width={171}
        height={171}
        src={Ellipse3}
      />
    ),
    title: "Patrick Denny",
    jd: "CTO",
    content: (
      <div style={contentStyle}>
        <p style={{ width: "90%" }}>
          I am absolutely thrilled to share my experience with Eastman
          Innovations. As a client, my journey with them has been nothing short
          of exceptional. From the very beginning, they displayed a remarkable
          level of professionalism, dedication, and technical prowess that truly
          sets them apart in the industry.
        </p>
      </div>
    ),
  },
  {
    key: 2,
    image: (
      <img
        style={{
          margin: "0 auto",
          marginTop: "-51px",
          display: "flex",
          borderRadius: "100px",
        }}
        width={171}
        height={171}
        src={Ellipse2}
      />
    ),
    title: "Akshey Khana",
    jd: "CEO",
    content: (
      <div style={contentStyle}>
        <p style={{ width: "90%" }}>
          Eastman Innovations has completely wowed me. Their knowledge is
          visible in the flawless programme they provided. The team's
          professionalism, on-time delivery, and outstanding communication make
          them an excellent choice for any job.
        </p>
      </div>
    ),
  },
  {
    key: 3,
    image: (
      <img
        style={{
          margin: "0 auto",
          marginTop: "-51px",
          display: "flex",
          borderRadius: "100px",
        }}
        width={171}
        height={171}
        src={Ellipse1}
      />
    ),
    title: "Nickson Milien",
    jd: "CEO",
    content: (
      <div style={contentStyle}>
        <p style={{ width: "90%" }}>
          Eastman Innovations' outcomes have exceeded my expectations. They
          turned my concept into an exceptional product that exceeded my
          expectations. Their passion, technical expertise, and collaborative
          attitude made the development process a breeze.
        </p>
      </div>
    ),
  },
];

export default class Example extends Component {
  state = {
    goToSlide: 0,
    offsetRadius: 2,
    showNavigation: true,
    config: config.gentle,
  };

  render() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: "1280px",
          margin: "0 auto",
        }}
      >
        <VerticalCarousel
          slides={slides}
          offsetRadius={this.state.offsetRadius}
          showNavigation={this.state.showNavigation}
          animationConfig={this.state.config}
        />
      </div>
    );
  }
}
