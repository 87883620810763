import React, { useEffect } from "react";
import "./LetsTalk.css";
import { Link } from "react-router-dom";
import icon from "../../../assets/Icon.png";
import linkdin from "../../../assets/linkdin.png";
import "./style.css";
import letstalk from "../../../assets/letstalk.png";
import contactus from "../../../assets/contactus.png";
import phone from "../../../assets/phone.png";
import Form from "react-bootstrap/Form";

import cellphone from "../../../assets/cellphone.png";
import email from "../../../assets/email.png";
import person from "../../../assets/person.png";
import { useNavigate } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import ContactUs from "../Home/ContactUs";
import ContectUs from "./letstalkform";
const LetsTalkDesktop = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [navigate]);
  return (
    <div
      style={{
        backgroundColor: "white",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      <div
        className="LetsTalk-Main-Div"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          width: "100%",
          height: 750,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            width: "100%",
            alignItems: "center",
            backgroundColor: " #F9F9F9",
          }}
        >
          <Navbar color={"#160761"} />
          <div
            className="Main-Div-Comp"
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "80%",
              flexDirection: "row-reverse",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: 100,
              }}
            >
              <img className="image" alt="Image" src={letstalk} />
            </div>

            <div className="Main-Heading-div" style={{ marginTop: 200 }}>
              <p
                className="letsTalk-Text"
                style={{
                  color: "#01EFF7",
                  fontSize: "40px",
                  fontWeight: "600",
                  margin: "10px 0",
                }}
              >
                Let’s talk
              </p>
              <p
                className="letsTalk-Text"
                style={{
                  color: "#265771",
                  fontSize: "40px",
                  fontWeight: "600",
                  margin: "10px 0",
                }}
              >
                Our team of <span style={{ color: "#2F89FC" }}>Eastman</span>
              </p>
              <p
                className="letsTalk-Text"
                style={{
                  color: "#265771",
                  fontSize: "40px",
                  fontWeight: "600",
                  margin: "10px 0",
                }}
              >
                side by side with your help!
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        className="P2"
        style={{
          width: "70%",
          height: 252,
          zIndex: 1,
          display: "flex",
          background:
            "linear-gradient(178.62deg, #01EFF7 1.18%, rgba(1, 239, 247, 0) 98.89%)",
          borderRadius: "15px 0px 15px 15px",
          marginTop: -100,
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        <div style={{ textAlign: "center" }}>
          <p
            className="experiance"
            style={{
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: 72,
              color: " #000000",
              margin: 0,
            }}
          >
            8+
          </p>
          <p
            className="years"
            style={{
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: 22,
              color: "#535353",
            }}
          >
            Years of experience
          </p>
        </div>
        <div style={{ textAlign: "center" }}>
          <p
            className="experiance"
            style={{
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: 72,
              color: " #000000",
              margin: 0,
            }}
          >
            50+
          </p>
          <p
            className="years"
            style={{
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: 22,
              color: "#535353",
            }}
          >
            Happy Clients
          </p>
        </div>

        <div style={{ textAlign: "center" }}>
          <p
            className="experiance"
            style={{
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: 72,
              color: " #000000",
              margin: 0,
            }}
          >
            200+
          </p>
          <p
            className="years"
            style={{
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: 22,
              color: "#535353",
            }}
          >
            Successful Projects
          </p>
        </div>
      </div>
      <div
        className="P3"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          width: "100%",
          height: 1180,
          marginTop: -152,
          alignItems: "center",
        }}
      >
        <div
          className="P3-Div"
          style={{
            display: "flex",
            flexDirection: "row",
            width: "80%",
            alignItems: "center",
            backgroundColor: " #fff",
            justifyContent: "center",
          }}
        >
          <div>
            <img className="image" src={contactus} />
            <p
              className="Idea"
              style={{
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: 40,
                textAlign: "center",

                color: "#535353",
              }}
            >
              Pitch Us your idea? <br />
              We handle all the rest.
            </p>
          </div>

          <div>
            <p
              className="conact-us"
              style={{
                fontStyle: "normal",
                fontWeight: 700,
                fontSize: 60,
                color: "#535353",
              }}
            >
              Contact Us
            </p>
            <div
              style={{
                width: 191,
                height: 16,
                background: "#01EFF7",
                borderRadius: 5,
              }}
            ></div>
            <ContectUs />
          </div>
        </div>
      </div>
      {/* Footer */}
      <div
        className="footer"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          width: "100%",
          background:
            "linear-gradient(269.87deg, #042573 -2.21%, #32175E 103.58%)",
          paddingTop: 60,
        }}
      >
        <div
          className="Footer-components"
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "80%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div>
              <img
                onClick={() => navigate("/")}
                src={icon}
                style={{ cursor: "pointer", marginLeft: -10, marginBottom: 10 }}
              />
              <p
                style={{
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: 14,
                  color: "#FFFFFF",
                  cursor: "default",
                }}
              >
                Get Free Estimate
              </p>
              <a
                className="Email-font"
                style={{
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: 16,
                  color: "#FFFFFF",
                  textDecoration: "none",
                }}
                href="https://mail.google.com/mail/?view=cm&fs=1&to=ehsan@eastmanenterprises.com"
                target="_blank"
              >
                ehsan@eastmanenterprises.com
              </a>
            </div>

            <div
              className="Contect-Number"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <img width="20px" height="20px" src={phone} />
              <a
                href="tel:+923044243496"
                style={{
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: 16,
                  color: "#FFFFFF",
                  margin: 0,
                  textDecoration: "none",
                  marginLeft: 20,
                }}
              >
                +92 304 4243496
              </a>
            </div>
            <a
              style={{ marginBottom: 50 }}
              href="https://pk.linkedin.com/company/eastman-enterprises-pk"
              target="_blank"
            >
              <img
                className="linked"
                height="30px"
                width="30px"
                src={linkdin}
              />
            </a>
          </div>

          <div>
            <p
              style={{
                fontStyle: "normal",
                fontWeight: 700,
                fontSize: 18,
                marginBottom: 30,
                color: "#FFFFFF",
                cursor: "default",
              }}
            >
              Company
            </p>
            <p
              style={{
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: 14,

                color: "#FFFFFF",
                marginBottom: 22,
                opacity: 0.78,
                cursor: "pointer",
              }}
              onClick={() => navigate("/")}
            >
              Home
            </p>
            <p
              style={{
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: 14,
                cursor: "pointer",
                color: "#FFFFFF",
                marginBottom: 22,
                opacity: 0.78,
              }}
              onClick={() => navigate("/technologies")}
            >
              Technologies
            </p>
            <p
              style={{
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: 14,
                cursor: "pointer",
                color: "#FFFFFF",
                marginBottom: 22,
                opacity: 0.78,
              }}
              onClick={() => navigate("/portfolio")}
            >
              Portfolio
            </p>
            <p
              style={{
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: 14,
                cursor: "pointer",
                color: "#FFFFFF",
                marginBottom: 22,
                opacity: 0.78,
              }}
              onClick={() => navigate("/career")}
            >
              Career
            </p>
          </div>

          <div>
            <p
              style={{
                fontStyle: "normal",
                fontWeight: 700,
                fontSize: 18,
                marginBottom: 30,
                color: "#FFFFFF",
                cursor: "default",
              }}
            >
              Services
            </p>
            <p
              style={{
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: 14,

                color: "#FFFFFF",
                marginBottom: 22,
                opacity: 0.78,
                cursor: "default",
              }}
            >
              Android App Development
            </p>
            <p
              style={{
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: 14,

                color: "#FFFFFF",
                marginBottom: 22,
                opacity: 0.78,
                cursor: "default",
              }}
            >
              AngularJs Development
            </p>
            <p
              style={{
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: 14,
                cursor: "default",
                color: "#FFFFFF",
                marginBottom: 22,
                opacity: 0.78,
              }}
            >
              PHP Development
            </p>
            <p
              style={{
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: 14,
                cursor: "default",
                color: "#FFFFFF",
                marginBottom: 22,
                opacity: 0.78,
              }}
            >
              CodeIgniter Development
            </p>
            <p
              style={{
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: 14,
                cursor: "default",
                color: "#FFFFFF",
                marginBottom: 22,
                opacity: 0.78,
              }}
            >
              Net Development
            </p>
            <p
              style={{
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: 14,
                cursor: "default",
                color: "#FFFFFF",
                marginBottom: 22,
                opacity: 0.78,
              }}
            >
              Augmented Reality
            </p>
            <p
              style={{
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: 14,
                cursor: "default",
                color: "#FFFFFF",
                marginBottom: 22,
                opacity: 0.78,
              }}
            >
              AWS Development
            </p>
          </div>
          <ContactUs />
        </div>
      </div>
    </div>
  );
};

export default LetsTalkDesktop;
