import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import LetsTalkDesktop from "./LetsTalk";
import person from "../../../assets/person.png";
import cellphone from "../../../assets/cellphone.png";
import emaill from "../../../assets/email.png";
import Form from "react-bootstrap/Form";

const ContectUs = () => {
  const form = useRef();
  const [Firstname, setfirstName] = useState("");
  const [Lastname, setlastName] = useState("");
  const [email, setemail] = useState("");
  const [number, setnumber] = useState("");
  const [Message, setmessage] = useState("");
  const [isValid, setIsValid] = useState(false);
  let handleEmailChange = (e) => {
    const enteredEmail = e.target.value;
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const isValidEmail = emailRegex.test(enteredEmail);
    setemail(enteredEmail);
    setIsValid(isValidEmail);
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_kpjsqdk",
        "template_0ll83b2",
        form.current,
        "ovAClfrkuNYnt26f-"
      )
      .then(
        (result) => {
          console.log(result.text);
          form.current.reset();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <form
      style={{ marginTop: 10, width: "100%" }}
      ref={form}
      onSubmit={sendEmail}
    >
      <div style={{ display: "flex" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            height: 58,
            background: "#FAFAFA",
            border: " 2px solid #EDEDED",
            borderRadius: 10,
            marginRight: 20,
          }}
        >
          <div>
            <img src={person} />
          </div>
          <input
            required
            type="text"
            onChange={(e) => setfirstName(e.target.value)}
            style={{
              width: "120%",
              height: 40,
              background: " #FAFAFA",
              borderColor: "transparent",
              borderRadius: 10,
              border: "none",
            }}
            maxLength={10}
            placeholder="First Name"
            name="user_name"
          />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            height: 58,
            background: "#FAFAFA",
            border: " 2px solid #EDEDED",
            borderRadius: 10,
            marginRight: 20,
          }}
        >
          <div>
            <img src={person} />
          </div>
          <input
            required
            onChange={(e) => setlastName(e.target.value)}
            type="text"
            style={{
              width: "120%",
              height: 40,
              background: " #FAFAFA",
              borderColor: "transparent",
              borderRadius: 10,
              border: "none",
            }}
            placeholder="Last Name"
            name="user_last_name"
            maxLength={10}
          />
        </div>
      </div>
      <div style={{ display: "flex", marginTop: 10 }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            height: 58,
            background: "#FAFAFA",
            border: " 2px solid #EDEDED",
            borderRadius: 10,
            marginRight: 20,
          }}
        >
          <div>
            <img src={emaill} />
          </div>
          <input
            required
            onChange={(e) => handleEmailChange(e)}
            type="email"
            style={{
              width: "120%",
              height: 40,
              background: "#FAFAFA",
              border: "none",
              borderRadius: 10,
            }}
            placeholder="Email"
            name="user_Email"
          />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            height: 58,
            background: "#FAFAFA",
            border: " 2px solid #EDEDED",
            borderRadius: 10,
            marginRight: 20,
          }}
        >
          <div>
            <img src={cellphone} />
          </div>
          <input
            required
            onChange={(e) => setnumber(e.target.value)}
            type="number"
            style={{
              width: "120%",
              height: 40,
              background: "#FAFAFA",
              border: "none",
              borderRadius: 10,
            }}
            placeholder="Phone Number"
            name="user_phonenumber"
          />
        </div>
      </div>
      <div style={{ width: "98%" }}>
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Control
            onChange={(e) => setmessage(e.target.value)}
            name="message"
            style={{ background: "#FAFAFA", marginTop: 10 }}
            placeholder="Please tell us how we may help you?"
            as="textarea"
            rows={7}
          />
        </Form.Group>
      </div>
      <input
        disabled={
          Firstname.length <= 3 ||
          Lastname.length <= 3 ||
          !isValid ||
          number.length <= 8 ||
          Message.length <= 3 ||
          email.length <= 5
        }
        style={{
          width: 190,
          background:
            "linear-gradient(264.78deg, #1E43DC -11.3%, #0BA2EA 129.77%)",
          boxShadow: "0px 22px 11px -20px rgba(0, 0, 0, 0.15)",
          borderRadius: 5,
          color: "#ffffff",
          textAlign: "center",
          padding: "15px 26px",
          fontSize: 18,
          border: "none",
          fontWeight: 700,
        }}
        type="submit"
        value="Send"
      />
    </form>
  );
};
export default ContectUs;
