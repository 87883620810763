import React from "react";
import styled from "@emotion/styled";
import { useSpring, animated } from "react-spring";
import { useDrag } from "@use-gesture/react";

const SlideContainer = styled.div`
  position: absolute;
  height: 70%;
  top: 16%;
  right: 50%;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform-origin: 50% 50%;
`;

const SlideCard = styled.div`
  position: relative;
  width: 730px;
  height: 439px;
  background: white;
  font-size: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform-origin: 50% 50%;
`;

function Slide({
  title,
  image,
  jd,
  content,
  offsetRadius,
  index,
  animationConfig,
  moveSlide,
}) {
  const offsetFromMiddle = index - offsetRadius;
  const totalPresentables = 2 * offsetRadius + 1;
  const distanceFactor = 1 - Math.abs(offsetFromMiddle / (offsetRadius + 1));

  // console.log('ofsetTTTTTop=========> ',offsetFromMiddle)
  // console.log('ofsetRRRRadius=========> ',offsetFromMiddle)
  // console.log('IIIIndex=========> ',index)

  const offsetCardClick = (i) => {
    console.log(i);
  };

  const translateYoffset =
    50 * (Math.abs(offsetFromMiddle) / (offsetRadius + 1));
  let translateY = -50;

  if (offsetRadius !== 0) {
    if (index === 0) {
      translateY = 0;
    } else if (index === totalPresentables - 1) {
      translateY = -100;
    }
  }

  const bind = useDrag(
    ({ down, movement: [, dy] }) => {
      if (offsetFromMiddle === 0 && down) {
        translateY += dy / (offsetRadius + 1);
        if (translateY > -40) {
          moveSlide(-1);
        }
        if (translateY < -100) {
          moveSlide(1);
        }
      }
    },
    {
      eventOptions: { passive: false },
    }
  );

  const TTop = 50 + (offsetFromMiddle * 50) / offsetRadius;

  const Scale = distanceFactor;

  const Opacity = distanceFactor * distanceFactor;
  // transform: translateX(0%) translateY(0%) scale(0.9);
  // top: 12%;
  // opacity: 1.25;
  const style = useSpring({
    transform: `translateX(0%) translateY(${translateY}%) scale(${
      TTop === 0 ? 0.9 : Scale
    })`,
    top: `${
      offsetRadius === 0 ? 50 : TTop === 0 ? 12 : TTop === 25 ? 50 : TTop
    }%`,
    opacity: TTop === 0 ? 1.25 : Opacity,
    config: animationConfig,
  });

  return (
    <animated.div
      {...bind()}
      style={{
        ...style,
        zIndex: Math.abs(Math.abs(offsetFromMiddle) - 2),
        position: "relative  ",
      }}
    >
      <SlideContainer>
        <SlideCard onClick={() => moveSlide(offsetFromMiddle)}>
          <div
            style={{
              width: "730px",
              height: "439px",
              borderRadius: "20px",
              backgroundColor: "#fff",
              boxShadow:
                "0px 18px 52.85371780395508px 0px rgba(215, 228, 249, 0.50)",
            }}
          >
            {image}
            <p
              style={{
                color: "#272D4E",
                textAlign: "center",
                fontSize: 18,
                fontStyle: "normal",
                fontWeight: 700,
                marginTop: "20px",
                marginBottom: "5px",
              }}
            >
              {title}
            </p>
            <p
              style={{
                color: "#272D4E",
                textAlign: "center",
                fontSize: 16,
                fontStyle: "normal",
                fontWeight: 500,
                marginBottom: "25px",
              }}
            >
              {jd}
            </p>
            <p
              style={{
                color: "#272D4E",
                textAlign: "center",
                fontSize: 20,
                fontStyle: "normal",
                fontWeight: 400,
              }}
            >
              {content}
            </p>
          </div>
        </SlideCard>
      </SlideContainer>
    </animated.div>
  );
}

export default Slide;
