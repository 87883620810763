import React from 'react'
import ReactDOM from 'react-dom/client'
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import './index.css'
import HomeDesktop from './screens/Desktop/Home/Home'
import CareerDesktop from './screens/Desktop/Career/Career'
import LetsTalkDesktop from './screens/Desktop/LetsTalk/LetsTalk'

import reportWebVitals from './reportWebVitals'
import Technologies from './screens/Desktop/Technologies/Technologies'
import Portfolio from './screens/Desktop/Portfolio/Portfolio'
import Example from './screens/Desktop/Home/Slider/example'

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomeDesktop/>,
  },
  {
    path: "/career",
    element: <CareerDesktop/>,
  },
  {
    path: "/lets-talk",
    element: <LetsTalkDesktop/>,
  },
  {
    path: "/technologies",
    element: <Technologies/>,
  },
  {
    path: "/portfolio",
    element: <Portfolio/>,
  },
  {
    path: "/example",
    element: <Example/>,
  }
])

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()